<template>
  <!-- v-model="isCampaignComposeDialogActive" -->
  <v-dialog
    :value="isCampaignComposerDialogActiveLocal"
    fullscreen
    width="500"
    @click:outside="$emit('update:is-campaign-compose-dialog-active',false)"
  >
    <!-- Botón que activa el dialogo:  -->
    <!-- @click="$emit('update:is-campaign-compose-dialog-active',true);" -->
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        outlined
        color="primary"
        v-bind="attrs"
        v-on="on"
        @click="$emit('on-open', null);"
      >
        <v-icon size="22">
          {{ icons.mdiPlus }}
        </v-icon>
        <span class="ml-2">Campaign</span>
      </v-btn>
    </template>

    <!-- Content -->
    <v-card class="rounded-0 text-sm">
      <v-toolbar
        dark
        color="primary"
      >
        <v-btn
          icon
          dark
          @click="$emit('update:is-campaign-compose-dialog-active',false);"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
        <v-toolbar-title>{{ campaignLocal.name ? campaignLocal.name : 'New Campaign' }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items class="d-flex align-center">
          <!-- Draft -->
          <v-btn
            :icon="false"
            outlined
            small
            class="me-2"
            :color="customActionDraft.color"
            @click="onOutsideCampaignSaveAsDraft()"
          >
            <v-icon size="22">
              {{ customActionDraft.icon }}
            </v-icon>
            <span class="pl-2">{{ customActionDraft.title }}</span>
          </v-btn>

          <!-- Published -->
          <v-btn
            :icon="false"
            outlined
            small
            class="me-2"
            :color="customActionPublished.color"
            @click="onOutsideCampaignSaveAsPublished()"
          >
            <v-icon size="22">
              {{ customActionPublished.icon }}
            </v-icon>
            <span class="pl-2">{{ customActionPublished.title }}</span>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <!-- Form content -->
      <CampaignComposerWizard
        ref="refCampaignComposerWizard"
        :campaign.sync="campaignLocal"
        :is-campaign-composer-dialog-active.sync="isCampaignComposerDialogActiveLocal"
        @on-save="(campaign) => $emit('on-save',campaign)"
      ></CampaignComposerWizard>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mdiPlus, mdiClose, mdiEmailSendOutline, mdiCircleEditOutline,
} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

import useCampaignHandler from '../campaign/useCampaignHandler'
import CampaignComposerWizard from './CampaignComposerWizard.vue'

export default {
  components: {
    CampaignComposerWizard,
  },
  model: {
    prop: 'isCampaignComposeDialogActive',
    event: 'update:is-campaign-compose-dialog-active',
  },
  props: {
    isCampaignComposeDialogActive: {
      type: Boolean,
      required: true,
    },
    campaign: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const {
      customActionDraft,
      customActionPublished,

      // resolveCampaignLabelColor,
      // resolveCampaignStatus,
      // CAMPAIGN_STATUS,
    } = useCampaignHandler()

    const campaignLocal = ref(props.campaign)
    const isCampaignComposerDialogActiveLocal = ref(props.isCampaignComposeDialogActive)
    watch(
      [
        () => props.campaign,
        () => props.isCampaignComposeDialogActive,
      ], () => {
        campaignLocal.value = props.campaign
        isCampaignComposerDialogActiveLocal.value = props.isCampaignComposeDialogActive
      },
    )

    // Outside control (Save outside form => Call form validations)
    const refCampaignComposerWizard = ref(null)
    const onOutsideCampaignSaveAsDraft = () => {
      refCampaignComposerWizard.value.onOutsideCampaignSaveAsDraft()
    }
    const onOutsideCampaignSaveAsPublished = () => {
      refCampaignComposerWizard.value.onOutsideCampaignSaveAsPublished()
    }

    return {
      campaignLocal,
      isCampaignComposerDialogActiveLocal,

      onOutsideCampaignSaveAsDraft,
      onOutsideCampaignSaveAsPublished,

      // Custom Actions
      refCampaignComposerWizard, // To launch custom actions
      customActionDraft,
      customActionPublished,

      // UI Campaign
      // resolveCampaignLabelColor,
      // resolveCampaignStatus,
      // CAMPAIGN_STATUS,

      icons: {
        mdiPlus,
        mdiClose,
        mdiEmailSendOutline,
        mdiCircleEditOutline,
      },
    }
  },
}
</script>
