<template>
  <!-- Multiple: Basic Actions : Labels -->
  <v-menu
    offset-y
    min-width="140"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        v-show="isVisible"
        icon
        small
        v-bind="attrs"
        class="me-3"
        v-on="on"
      >
        <v-icon size="22">
          {{ icons.mdiLabelOutline }}
        </v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="label in updateLabelMenuListItems"
        :key="label.title"
        link
        @click="$emit('on-label-click', label.value)"
      >
        <v-list-item-icon class="align-self-center">
          <v-badge
            inline
            :color="label.color"
            dot
            class="mb-1"
          ></v-badge>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ label.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiLabelOutline,
} from '@mdi/js'

export default {
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    updateLabelMenuListItems: {
      type: Array,
      required: true,
    },
  },
  setup() {
    return {
      icons: {
        mdiLabelOutline,
      },
    }
  },
}
</script>

<style>

</style>
