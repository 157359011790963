import axios from '@axios'

// interface ContactInterface {
//     id: string;
//     name: string;
// }

// Contact Router list
// GET    /contacts             Retrieves the collection of Contact resources (By user)
// POST   /contacts             Creates a Contact resource.
// GET    /contacts/{id}        Retrieves a Contact resource.
// PUT    /contacts/{id}        Replaces a Contact resource.
// DELETE /contacts/{id}        Removes a Contact resource.
const routeContacts = '/contacts'

// URL connection
//  8060 -> symfony API
//  8062 -> @fake-db
const API_BASE_MODULE = process.env.VUE_APP_API_BASE

// const API_BASE_MODULE = '' // @fake-db
// const API_BASE_MODULE = undefined // Take default BaseApiService

// class Contact extends BaseApiService { constructor() { super(axios) }
class Contact {
  axiosIns = axios

  API_BASE = API_BASE_MODULE !== undefined ? API_BASE_MODULE : this.baseUrl

  fetchContacts(queryParams) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}${routeContacts}`, { params: queryParams })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // Not in use -> Los contactos se crea via: CampaignInbound o ContactRequestInbound
  createContact(contactData) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .post(`${this.API_BASE}${routeContacts}`, contactData)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  fetchContact(id) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}${routeContacts}/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // Generic update
  // Request1:  payload = { status: newStatus}
  // Request2:  payload = { tags: newTags}
  // Response: Contact
  updateContact(contactId, payload) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .put(`${this.API_BASE}${routeContacts}/${contactId}`, { ...payload })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // Request:  payload = { status: newStatus}
  // Response: Contact
  updateContactStatus(contactId, payload) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .put(`${this.API_BASE}${routeContacts}/${contactId}`, { ...payload })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // Response: void
  removeContact(id) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .delete(`${this.API_BASE}${routeContacts}/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // Reasign Contact (Change User)
  reasignContact(contactId, userId) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .post(`${this.API_BASE}${routeContacts}/reasign/${contactId}`, { userId })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}

const useContact = new Contact()
export default useContact
