<template>
  <!-- Main card -->
  <v-card class="rounded-0 app-campaign h-full position-relative overflow-hidden text-sm">
    <!-- Dialog confirm publish -->
    <CampaignPublishDialog
      v-model="isCampaignPublishDialogVisible"
      :campaign="campaignPublishDialog"
      @ok="(campaign) => askCampaignPublishDialogResponse(campaign)"
    ></CampaignPublishDialog>

    <!-- Delete -->
    <DialogDelete
      :id="campaignDeleteId"
      v-model="isCampaignDeleteDialogActive"
      :dependencies-error="campaignDeleteDependenciesError"
      :title="$t('CampaignList.table.dialog_delete.title')"
      :text="$t('CampaignList.table.dialog_delete.text')"
      :button="$t('CampaignList.table.dialog_delete.button_text')"
      @delete="(id) => onCampaignDelete(id, false)"
    ></DialogDelete>

    <!-- Left Sidebar: Filters -->
    <v-navigation-drawer
      v-model="isLeftSidebarOpen"
      width="260"
      mobile-breakpoint="sm"
      absolute
      :right="$vuetify.rtl"
      touchless
      :temporary="$vuetify.breakpoint.smAndDown"
    >
      <campaign-left-sidebar-content
        :is-campaign-compose-dialog-visible="isCampaignComposeDialogVisible"
        :campaigns-meta="campaignsMeta"
      ></campaign-left-sidebar-content>
    </v-navigation-drawer>

    <!-- Content Area -->
    <div
      class="h-full position-relative"
      :style="contentStyles"
    >
      <!-- 1st Row -->
      <div class="d-flex align-center px-4">
        <v-btn
          v-show="!isLeftSidebarOpen"
          :icon="false"
          outlined
          small
          color="primary"
          @click="isLeftSidebarOpen = true"
        >
          <v-icon size="22">
            {{ icons.mdiTune }}
          </v-icon>
          <span class="pl-2">{{ $route.params.folder || $route.params.label }}</span>
        </v-btn>

        <v-text-field
          v-model="searchQuery"
          placeholder="search..."
          outlined
          hide-details
          class="campaign-search-input"
          :prepend-inner-icon="icons.mdiMagnify"
        ></v-text-field>
      </div>

      <v-divider></v-divider>

      <!-- 2nd Row -->
      <div class="d-flex align-center px-4 py-2">
        <v-checkbox
          class="d-flex mt-0 pt-0"
          hide-details
          :input-value="selectAllCampaignCheckbox"
          :indeterminate="isSelectAllCampaignCheckboxIndeterminate"
          :disabled="!campaigns.length"
          @change="selectAllCheckboxUpdate"
        ></v-checkbox>

        <!-- Action Multiple: Labels -->
        <MenuLabels
          :is-visible="(selectedCampaigns.length > 0)"
          :update-label-menu-list-items="updateLabelMenuListItems"
          @on-label-click="(value) => updateSelectedCampaignLabelMultiple(value)"
        ></MenuLabels>

        <!-- Multiple: Custom Actions -->
        <div
          v-if="selectedCampaigns.length"
          class="ml-2"
        >
          <!-- Published: Las puedo finalizar -->
          <v-btn
            v-if="$route.params.folder === CAMPAIGN_STATUS.PUBLISHED"
            icon
            small
            class="me-2"
            :color="customActionFinished.color"
            @click.stop="setSelectedCampaignActionMultiple(customActionFinished.value)"
          >
            <v-icon size="22">
              {{ customActionFinished.icon }}
            </v-icon>
          </v-btn>

          <!-- Deshabilitado: Se publicaran individualmente -->
          <!-- Draft: Las puedo publicar -->
          <!-- <v-btn
            v-if="$route.params.folder === CAMPAIGN_STATUS.DRAFT"
            icon
            small
            class="me-2"
            :color="customActionPublished.color"
            @click.stop="setSelectedCampaignActionMultiple(customActionPublished.value)"
          >
            <v-icon size="22">
              {{ customActionPublished.icon }}
            </v-icon>
          </v-btn> -->
        </div>

        <!-- Right Side -->
        <v-spacer></v-spacer>

        <!-- Dialog + button -->
        <!-- <v-btn
          small
          outlined
          color="primary"
          @click="isCampaignComposeDialogVisible = true"
        >
          <v-icon size="22">
            {{ icons.mdiPlus }}
          </v-icon>
          <span class="ml-2">Campaign</span>
        </v-btn> -->

        <!-- v-model="isCampaignComposeDialogVisible" -->
        <CampaignComposerDialog
          :is-campaign-compose-dialog-active.sync="isCampaignComposeDialogVisible"
          :campaign.sync="selectedCampaignCompose"
          @on-open="(campaign) => setCampaignComposerActive(campaign)"
          @on-save="(campaign) => onSaveSelectedCampaignCompose(campaign)"
        ></CampaignComposerDialog>

        <v-btn
          icon
          small
          class="me-2"
          @click="fetchCampaigns"
        >
          <v-icon size="21">
            {{ icons.mdiReload }}
          </v-icon>
        </v-btn>
      </div>

      <!-- Loading indicator -->
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
        class="position-absolute"
      ></v-progress-linear>
      <v-divider></v-divider>

      <!-- Campaign List -->
      <perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="ps-campaign-list"
      >
        <v-hover
          v-for="campaign in campaigns"
          #default="{ hover: isHovered }"
          :key="campaign.id"
        >
          <div
            :class="[{'hovered elevation-3': isHovered}]"
            class="campaign-item pa-4 d-flex align-center text-no-wrap cursor-pointer"
            @click="updateCampaignView(campaign)"
          >
            <v-checkbox
              class="d-flex mt-0 pt-0"
              hide-details
              :input-value="selectedCampaigns.includes(campaign.id)"
              @click.stop
              @change="toggleSelectedCampaign(campaign.id)"
            ></v-checkbox>
            <v-btn
              v-if="$vuetify.breakpoint.smAndUp"
              icon
              small
              class="me-2"
              :color="campaign.isStarred ? 'warning': null"
              @click.stop="toggleStarred(campaign)"
            >
              <v-icon>
                {{ campaign.isStarred ? icons.mdiStar : icons.mdiStarOutline }}
              </v-icon>
            </v-btn>

            <!-- Campaign Card -->
            <CampaignCard :campaign="campaign"></CampaignCard>

            <v-spacer></v-spacer>

            <!-- Time and Actions On Hover -->
            <div v-show="isHovered">
              <!-- Custom Actions: Published | Finished | Go to EDIT Draft -->

              <!-- Draft: La puedo pubicar o editar -->
              <!-- @click.stop="setSelectedCampaignActionMultiple(customActionPublished.value, [campaign.id])" -->
              <v-btn
                v-if="campaign.status === CAMPAIGN_STATUS.DRAFT"
                icon
                small
                outlined
                class="me-2"
                :color="customActionPublished.color"
                @click.stop="askCampaignPublishDialog(campaign)"
              >
                <v-icon size="22">
                  {{ customActionPublished.icon }}
                </v-icon>
              </v-btn>

              <!-- Draft: EDIT -->
              <v-btn
                v-if="campaign.status === CAMPAIGN_STATUS.DRAFT"
                icon
                small
                outlined
                class="me-2"
                :color="customActionDraft.color"
                @click.stop="setCampaignComposerActive(campaign)"
              >
                <v-icon size="22">
                  {{ customActionDraft.icon }}
                </v-icon>
                <!-- <span class="pl-2">{{ customActionDraft.title }}</span> -->
              </v-btn>

              <!-- Draft: DELETE -->
              <v-btn
                v-if="campaign.status === CAMPAIGN_STATUS.DRAFT"
                icon
                small
                outlined
                class="ml-2"
                color="error"
                @click.stop="askCampaignDelete(campaign.id)"
              >
                <v-icon size="22">
                  {{ icons.mdiTrashCanOutline }}
                </v-icon>
              </v-btn>

              <!-- Published: La puedo finalizar -->
              <v-btn
                v-if="campaign.status === CAMPAIGN_STATUS.PUBLISHED"
                icon
                small
                class="me-2"
                :color="customActionFinished.color"
                @click.stop="setSelectedCampaignActionMultiple(customActionFinished.value, [campaign.id])"
              >
                <v-icon size="22">
                  {{ customActionFinished.icon }}
                </v-icon>
              </v-btn>

              <!-- Action: Delete permanent (Only when trash) -->
              <v-btn
                v-show="$route.params.folder === 'trash'"
                icon
                small
                class="me-2"
                @click.stop="deleteSelectedCampaignMultiple([campaign.id])"
              >
                <v-icon size="22">
                  {{ icons.mdiTrashCanOutline }}
                </v-icon>
              </v-btn>
            </div>

            <!-- Not hovered: Status + Time -->
            <div v-show="!isHovered">
              <div class="d-flex flex-column align-center">
                <span class="text-xs text--disabled">{{ formatDateToMonthShort(campaign.createdAt, { hour: 'numeric', minute: 'numeric', }) }}</span>
                <v-badge
                  inline
                  class="email-label-chip text-capitalize v-badge-light-bg"
                  :class="`${resolveCampaignStatus(campaign.status).color}--text`"
                  :content="campaign.status"
                ></v-badge>
              </div>
            </div>
          </div>
        </v-hover>
        <div
          class="d-none text-center text--primary font-weight-medium"
          :class="{'d-block': !campaigns.length}"
        >
          <p class="my-4">
            No Items Found
          </p>
        </div>
      </perfect-scrollbar>

      <!-- Campaign Details -->
      <v-navigation-drawer
        v-model="isCampaignViewOpen"
        :right="!$vuetify.rtl"
        absolute
        mobile-breakpoint="sm"
        width="100%"
      >
        <!-- @move-campaign-to-folder="moveOpenCampaignToFolder" -->
        <!-- @mark-campaign-unread="markOpenCampaignAsUnread" -->
        <campaign-view
          v-if="campaignView.productSupplier"
          :campaign-view="campaignView"
          :open-item-meta="openCampaignMeta"
          @change-open-item="changeOpenCampaign"
          @close-campaign-view="closeCampaignView()"
          @update-campaign-label="updateOpenCampaignLabel"

          @toggle-campaign-starred="(campaign) => toggleStarred(campaign)"
          @set-campaign-action="(status) => setOpenCampaignAction(status)"
          @on-edit="(campaign) => setCampaignComposerActive(campaign)"
          @on-delete="(campaign) => askCampaignDelete(campaign.id)"
        ></campaign-view>
      </v-navigation-drawer>
    </div>
  </v-card>
</template>

<script>
import { useResponsiveLeftSidebar } from '@core/comp-functions/ui'
import { useRouter } from '@core/utils'
import {
  mdiTune,
  mdiMagnify,
  mdiTrashCanOutline,
  mdiReload,
  mdiStar,
  mdiStarOutline,
  mdiEmailOpenOutline,
  mdiEmailOutline,
  mdiDeleteOutline,
} from '@mdi/js'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

// Store
// eslint-disable-next-line object-curly-newline
import { formatDateToMonthShort } from '@core/utils/filter'
import { computed, ref, watch } from '@vue/composition-api'
import store from '@/store'

// Services
import useCampaign from '@/services/api/modules/campaign'
import useCampaignHandler from './useCampaignHandler'

// Local Componnets
// import ProductSupplierCard from '@/components/Cards/ProductSupplierCard.vue'
import CampaignLeftSidebarContent from './CampaignLeftSidebarContent.vue'

import CampaignView from './CampaignView.vue'
import CampaignComposerDialog from '../campaign-composer/CampaignComposerDialog.vue'
import CampaignPublishDialog from './CampaignPublishDialog.vue'
import CampaignCard from '@/components/Cards/CampaignCard.vue'
import MenuLabels from '@/components/MenuLabels.vue'

// Delete dialog
// import DialogDeleteDependencies from '@/components/Dialog/DialogDeleteDependencies.vue'
import DialogDelete from '@/components/DialogDelete.vue'

export default {
  components: {
    CampaignLeftSidebarContent,

    CampaignCard,
    CampaignView,
    CampaignComposerDialog,
    CampaignPublishDialog,

    DialogDelete,

    // 3rd Party
    PerfectScrollbar,
    MenuLabels,
  },
  setup() {
    // Register module
    // const MARKETING_APP_STORE_MODULE_NAME = 'marketplace'
    // if (!store.hasModule(MARKETING_APP_STORE_MODULE_NAME)) store.registerModule(MARKETING_APP_STORE_MODULE_NAME, marketplaceStoreModule)
    // onUnmounted(() => {
    //     if (store.hasModule(MARKETING_APP_STORE_MODULE_NAME)) store.unregisterModule(MARKETING_APP_STORE_MODULE_NAME)
    // })

    // LeftSidebar management (Integrated with Main Component)
    const { isLeftSidebarOpen, contentStyles } = useResponsiveLeftSidebar({ sidebarWidth: 260 })

    // ————————————————————————————————————
    //* ——— Router
    // ————————————————————————————————————
    const { route, router } = useRouter()

    // Search Query & Route Params
    const searchQuery = computed({
      get: () => route.value.query.q,
      set: val => {
        const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

        if (val) currentRouteQuery.q = val
        else delete currentRouteQuery.q

        router.replace({ name: route.name, query: currentRouteQuery })
      },
    })
    const routeParams = computed(() => route.value.params)

    // Fetch campaign again of search query change or route param change
    // eslint-disable-next-line no-use-before-define
    watch([searchQuery, routeParams], () => fetchCampaigns())

    // ————————————————————————————————————
    //* ——— useCampaign
    // ————————————————————————————————————
    const {
      sendCampaignNotification,
      resolveCampaignLabelColor,
      resolveCampaignStatus,
      customActionPublished,
      customActionFinished,
      customActionDraft,
      updateLabelMenuListItems,
      CAMPAIGN_STATUS,
    } = useCampaignHandler()

    // ————————————————————————————————————
    //* ——— Campaigns & Campaigns Meta (LeftSide)
    // ————————————————————————————————————
    const campaigns = ref([])
    const campaignsMeta = ref({})
    const loading = ref(false)

    const fetchCampaigns = () => {
      loading.value = true

      // folder meaning
      //  published filter by status (Default search)
      //  draft     filter by status
      //  finished  filter by status
      //  starred   filter by isStarred
      //  trash     filter by isDeleted or status deleted
      useCampaign
        .fetchCampaigns({
          q: searchQuery.value,

          // campaigId: campaignIdNavigation, // Only this campaign TODO

          // folder: router.currentRoute.params.folder || 'published',
          folder:
            router.currentRoute.params.folder
            || (router.currentRoute.params.label ? undefined : CAMPAIGN_STATUS.PUBLISHED),
          label: router.currentRoute.params.label,
        })
        .then(response => {
          const { campaigns: campaignsResponse, campaignsMeta: campaignsMetaResponse } = response.data
          if (campaignsResponse) {
            campaigns.value = campaignsResponse
            campaignsMeta.value = campaignsMetaResponse
          } else {
            campaigns.value = []
            campaignsMeta.value = {}
          }

          // Store
          // const totalPending = campaignsMeta.value['CAMPAIGN_STATUS.PUBLISHED'] || 0
          // const totalPending = campaignsMeta.value?.published || 0
          const { published } = campaignsMeta.value
          store.commit('user/UPDATE_CAMPAIGNS_PENDING_COUNT', published || 0)
        })
        .finally(() => {
          loading.value = false
        })
    }
    fetchCampaigns()

    // Navegación: Se ha indicado un id (Buscamos en los query params)
    //  Navegación automática -> TODO - Mejor abrir una modal de edición (Por ejemplo CampaignComposerDialog)
    const campaignIdNavigation = route.value.query.campaignId
    const fetchCampaign = campaignId => {
      useCampaign.fetchCampaign(campaignId)
        .then(response => {
          // eslint-disable-next-line
          updateCampaignView(response.data.campaign)
        })
    }
    if (campaignIdNavigation) {
      fetchCampaign(campaignIdNavigation)
    }

    // ————————————————————————————————————
    //* ——— Campaigns Selection
    // ————————————————————————————————————
    const selectedCampaigns = ref([])
    const toggleSelectedCampaign = campaignId => {
      const campaignIndex = selectedCampaigns.value.indexOf(campaignId)

      if (campaignIndex === -1) selectedCampaigns.value.push(campaignId)
      else selectedCampaigns.value.splice(campaignIndex, 1)
    }
    const selectAllCampaignCheckbox = computed(
      () => campaigns.value.length && campaigns.value.length === selectedCampaigns.value.length,
    )
    const isSelectAllCampaignCheckboxIndeterminate = computed(
      () => Boolean(selectedCampaigns.value.length)
        && campaigns.value.length !== selectedCampaigns.value.length,
    )
    const selectAllCheckboxUpdate = val => {
      selectedCampaigns.value = val ? campaigns.value.map(campaign => campaign.id) : []
    }

    // ? Watcher to reset selectedCampaigns is somewhere below due to watch dependecy fullfilment

    // ————————————————————————————————————
    //* ——— Campaign Actions
    // ————————————————————————————————————
    const toggleStarred = campaign => {
      useCampaign.changeCampaignStarred(
        campaign.id,
        !campaign.isStarred,
      )
        .then(() => {
        // eslint-disable-next-line no-param-reassign
          campaign.isStarred = !campaign.isStarred
        })
    }

    const updateSelectedCampaignLabelMultiple = (
      label,
      campaignIds = selectedCampaigns.value,
    ) => {
      const tasks = campaignIds.map(campaignId => useCampaign.changeCampaignLabels(campaignId, label))
      Promise.all(tasks).finally(() => {
        fetchCampaigns()
        selectedCampaigns.value = []
      })
    }

    // Pendiente (Igual no se permite)
    const deleteSelectedCampaignMultiple = (
      campaignIds = selectedCampaigns.value,
    ) => {
      const tasks = campaignIds.map(campaignId => useCampaign.removeCampaign(campaignId))
      Promise.all(tasks).finally(() => {
        fetchCampaigns()
        selectedCampaigns.value = []
      })
    }

    // From CampaignView: Create new, Edit current, Withdraw current
    //  actions: [published | finished]
    // const setCampaignAction = (
    //   action,
    //   campaignId,
    // ) => {
    //   // Withdraw action: Change status to FINISHED
    //   // if (action === CAMPAIGN_STATUS.PUBLISHED || action === CAMPAIGN_STATUS.FINISHED) {
    //   useCampaign.setCampaignStatus(campaignId, action)
    //     .then(() => {
    //       sendCampaignNotification(`Campaign ${action}`)
    //     })
    //     .finally(() => {
    //       fetchCampaigns()
    //       selectedCampaigns.value = []
    //     })
    // }
    const setSelectedCampaignActionMultiple = (
      status,
      campaignIds = selectedCampaigns.value,
    ) => {
      const tasks = campaignIds.map(campaignId => useCampaign.setCampaignStatus(campaignId, status))
      Promise.all(tasks)
        .finally(() => {
          fetchCampaigns()
          selectedCampaigns.value = []

          // Close after undate status
          // eslint-disable-next-line no-use-before-define
          closeCampaignView()
        })
    }

    // ————————————————————————————————————
    //* ——— Campaign View
    // ————————————————————————————————————
    const isCampaignViewOpen = ref(false)
    const campaignView = ref({})

    // >>> Open Meta (Prev < > Next)
    const openCampaignMeta = computed(() => {
      const openCampaignIndex = campaigns.value.findIndex(e => e.id === campaignView.value.id)

      return {
        hasNext: Boolean(campaigns.value[openCampaignIndex + 1]),
        hasPrev: Boolean(campaigns.value[openCampaignIndex - 1]),
      }
    })
    const changeOpenCampaign = dir => {
      const openCampaignIndex = campaigns.value.findIndex(e => e.id === campaignView.value.id)
      const newCampaignIndex = dir === 'prev' ? openCampaignIndex - 1 : openCampaignIndex + 1
      campaignView.value = campaigns.value[newCampaignIndex]
    }

    // >>> View Actions
    const closeCampaignView = () => {
      campaignView.value = {}
      isCampaignViewOpen.value = false
    }
    const updateCampaignView = campaign => {
      campaignView.value = campaign
      isCampaignViewOpen.value = true
    }

    const updateOpenCampaignLabel = label => {
      selectedCampaigns.value = [campaignView.value.id]
      updateSelectedCampaignLabelMultiple(label)

      // Update label in open campaign
      const labelIndex = campaignView.value.labels.indexOf(label)
      if (labelIndex === -1) campaignView.value.labels.push(label)
      else campaignView.value.labels.splice(labelIndex, 1)

      selectedCampaigns.value = []
    }

    // Actions: Publish, Finish
    const setOpenCampaignAction = status => {
      selectedCampaigns.value = [campaignView.value.id]

      if (status === CAMPAIGN_STATUS.PUBLISHED) {
        // eslint-disable-next-line no-use-before-define
        askCampaignPublishDialog(campaignView.value)
      } else {
        setSelectedCampaignActionMultiple(status)

        selectedCampaigns.value = []
        isCampaignViewOpen.value = false
      }
    }

    // * If someone clicks on filter while viewing detail => Close the campaign detail view
    watch(routeParams, () => {
      isCampaignViewOpen.value = false
    })

    // * Watcher to reset selectedCampaigns
    // ? You can also use isCampaignViewOpen (instead of `campaignView`) but it will trigger execution twice in this case
    // eslint-disable-next-line no-use-before-define
    watch([campaignView, routeParams], () => {
      selectedCampaigns.value = []
    })

    // Publish Campaign with Dialog
    const isCampaignPublishDialogVisible = ref(false)
    const campaignPublishDialog = ref(null)
    const askCampaignPublishDialog = campaign => {
      isCampaignPublishDialogVisible.value = true
      campaignPublishDialog.value = campaign
    }
    const askCampaignPublishDialogResponse = campaign => {
      setSelectedCampaignActionMultiple(CAMPAIGN_STATUS.PUBLISHED, [campaign.id])
    }

    // Delete with dialog ask
    const isCampaignDeleteDialogActive = ref(false)
    const campaignDeleteId = ref('')
    const campaignDeleteDependenciesError = ref(null)
    const askCampaignDelete = (campaignId, dempendenciesError = null) => {
      isCampaignDeleteDialogActive.value = true
      campaignDeleteId.value = campaignId

      campaignDeleteDependenciesError.value = dempendenciesError
    }

    const onCampaignDelete = (campaignId, force = false) => {
      debugger
      console.log(force)
      useCampaign
        .removeCampaign(campaignId)
        .then(() => {
          // TODO Unselect current selection
          debugger
          selectedCampaigns.value = []
          isCampaignViewOpen.value = false
          campaignView.value = {}

          sendCampaignNotification('Campaign Deleted', 'success')
          fetchCampaigns()
        })
        .catch(error => {
          // Checking... Dependencies error
          // if (error.response && error.response.data && error.response.data.error) {
          if (error.response && error.response.data && error.response.data.error) {
            if (error.response.data.code === 'campaign_dependencies_error') {
              // if (error.response.data.error.campaigns || error.response.data.error.contactRequests) {
              // Send dependencies
              //  campaigns: error.response.data.error.campaigns,
              //  contactRequests: error.response.data.error.contactRequests,
              askCampaignDelete(campaignId, error.response.data.error)
            } else {
              sendCampaignNotification(error.response.data.message, 'error')
            }
          }
        })
    }

    // ————————————————————————————————————
    //* ——— Compose
    // ————————————————————————————————————

    const isCampaignComposeDialogVisible = ref(false)
    const selectedCampaignCompose = ref({})

    // Action: The open campaign was save
    const onSaveSelectedCampaignCompose = campaign => {
      // Estoy en el visor de la Campaña ??
      if (campaignView.value?.id === campaign.id && isCampaignViewOpen.value) {
        campaignView.value = campaign
      } else {
        // Si estoy en una ruta de un estado diferente al que acabo de indicar en la campaña -> Move
        // eslint-disable-next-line no-lonely-if
        if (route.value.params?.folder && route.value.params?.folder !== campaign.status) {
          router.replace({ name: route.name, params: { folder: campaign.status } })
        }
      }

      // Reset all
      selectedCampaignCompose.value = {}
      isCampaignComposeDialogVisible.value = false
      fetchCampaigns()
    }

    // Actions: Open Campaign for edit
    //  1.- Desde CampaignView
    //  2.- Desde la lista de campañas (Aquí)
    //  3.- Desde el botón de ADD: interno[CampaignComposerDialog(Template)] o externo
    const setCampaignComposerActive = campaign => {
      // Checking... Add new campaign
      //  a) New campaign
      //  b) El selectedCampaignCompose último NO es de edición => keep last changes
      if (campaign === null && selectedCampaignCompose.value?.id !== undefined && selectedCampaignCompose.value?.id !== null) {
        selectedCampaignCompose.value = {}
      }

      // Checking... Edit the same again (Keep changes)
      //  a) Edit campaign
      //  b) El selectedCampaignCompose es diferente => keep last changes
      if (campaign !== null && selectedCampaignCompose.value?.id !== campaign?.id) {
        selectedCampaignCompose.value = campaign
      }

      isCampaignComposeDialogVisible.value = true
    }

    // ————————————————————————————————————
    //* ——— Perfect Scrollbar
    // ————————————————————————————————————
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      isLeftSidebarOpen,
      contentStyles,

      // Search Query
      searchQuery,

      // Campaigns & Campaign Meta
      campaigns,
      campaignsMeta,
      fetchCampaigns,
      loading,

      // Campaign Selection
      selectedCampaigns,
      toggleSelectedCampaign,
      selectAllCampaignCheckbox,
      isSelectAllCampaignCheckboxIndeterminate,
      selectAllCheckboxUpdate,

      // Campaign Actions
      toggleStarred,
      updateSelectedCampaignLabelMultiple,
      setSelectedCampaignActionMultiple,
      deleteSelectedCampaignMultiple,

      // Campaign View
      isCampaignViewOpen,
      campaignView,
      openCampaignMeta,
      closeCampaignView,
      updateCampaignView,
      updateOpenCampaignLabel,
      changeOpenCampaign,
      setOpenCampaignAction,

      // Compose
      isCampaignComposeDialogVisible,
      onSaveSelectedCampaignCompose,
      setCampaignComposerActive, // Método que abre la campaña
      selectedCampaignCompose, // Campaña a modificar

      // Campaign Ask Publish
      isCampaignPublishDialogVisible,
      campaignPublishDialog,
      askCampaignPublishDialog,
      askCampaignPublishDialogResponse,

      // useCampaign
      resolveCampaignLabelColor,
      resolveCampaignStatus,
      customActionPublished,
      customActionFinished,
      customActionDraft,
      updateLabelMenuListItems,
      CAMPAIGN_STATUS,

      // CRUD Delete
      isCampaignDeleteDialogActive,
      campaignDeleteId,
      campaignDeleteDependenciesError,
      askCampaignDelete,
      onCampaignDelete,

      // Perfect Scrollbar
      perfectScrollbarSettings,

      // Filter/Formatter
      formatDateToMonthShort,

      // Icons
      icons: {
        // Menu,
        mdiTune,
        mdiMagnify,

        // Actions
        mdiTrashCanOutline,
        mdiReload,
        mdiDeleteOutline,

        // Starred,
        mdiStarOutline,
        mdiStar,

        // Read / Unread
        mdiEmailOutline,
        mdiEmailOpenOutline,
      },
    }
  },
}
</script>

<style lang="scss">
  @import '~vuetify/src/styles/styles.sass';
  @import '~@core/preset/preset/mixins';

  .app-campaign {
    // Style search input
    .campaign-search-input {
      .v-input__slot {
        fieldset {
          border: none !important;
        }
        input {
          caret-color: var(--v-primary-base) !important;
        }
      }
    }
    // @include theme(campaign-search-input) using ($material) {
    //   .v-input__slot {
    //     input {
    //       caret-color: map-deep-get($material, 'text', 'primary') !important;
    //     }
    //   }
    // }
  }

  // Perfect Scroll
  .ps-campaign-list {
    // height: calc(100% - 96px - 2px); // 96+2 deben ser el footer que no lleva
    height: calc(100%);

    .campaign-item {
      &.hovered {
        transform: translateY(-2px);
        transition: all 0.2s;
        z-index: 1;
      }
    }
  }

  @include theme--child(ps-campaign-list) using ($material) {
    .campaign-item {
      &:not(:last-child) {
        border-bottom: 1px solid map-deep-get($material, 'dividers');
      }
      &.campaign-read {
        background-color: rgba(map-deep-get($material, 'primary-shade'), 0.04);
      }
    }
  }
</style>
