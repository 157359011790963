<template>
  <div>
    <v-card class="campaign-compose-card">
      <v-card-text>
        <p class="text-2xl font-weight-semibold text--primary mb-2">
          {{ $t('CampaignComposerWizard.body.tab1.title') }}
        </p>
        <p class="mb-2">
          {{ $t('CampaignComposerWizard.body.tab1.subtitle') }}
        </p>
      </v-card-text>

      <!-- Form -->
      <v-card-text>
        <v-form
          ref="form"
          @submit.prevent="validationForm"
        >
          <v-row>
            <!-- Campaign Name -->
            <v-col
              cols="12"
              xs="12"
              sm="6"
            >
              <v-text-field
                v-model="campaignLocal.name"
                outlined
                :label="$t('CampaignComposerWizard.body.tab1.form.name')"
                :placeholder="$t('CampaignComposerWizard.body.tab1.form.name_placeholder')"
                clearable
                hide-details="auto"
                :error-messages="errorMessages.name"
                :rules="[required]"
              ></v-text-field>
            </v-col>

            <!-- Product Supplier -->
            <v-col
              cols="12"
              xs="12"
              sm="6"
            >
              <v-select
                v-model="campaignLocal.productSupplier"
                :items="productSupplierFilterOptions"
                :rules="rules.select"
                :error-messages="errorMessages.productSupplier"
                :label="$t('CampaignComposerWizard.body.tab1.form.product_supplier')"
                :placeholder="$t('CampaignComposerWizard.body.tab1.form.product_supplier_placeholder')"
                item-text="name"
                return-object
                outlined
                :dense="false"
                clearable
                hide-details="auto"
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="campaignLocal.productSupplier = null"
                  >
                    <v-avatar left>
                      <!-- <v-img :src="data.item.image"></v-img> -->
                      <v-img :src="data.item.image || data.item.product.productCategory.image"></v-img>
                    </v-avatar>
                    <div class="d-flex flex-column">
                      <span>{{ data.item.name }}</span>
                      <span>{{ data.item.product.productCategory.name }}</span>
                    </div>
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content v-text="data.item"></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item
                      v-bind="data.attrs"
                      style="height: unset;"
                      v-on="data.on"
                    >
                      <v-list-item-avatar>
                        <img :src="data.item.image || data.item.product.productCategory.image">
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        <v-list-item-subtitle v-html="data.item.product.productCategory.name"></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </template>
              </v-select>
            </v-col>

            <!-- Quantity -->
            <v-col
              cols="12"
              xs="12"
              sm="6"
            >
              <v-text-field
                v-model="campaignLocal.quantity"
                outlined
                :label="$t('CampaignComposerWizard.body.tab1.form.quantity')"
                clearable
                hide-details="auto"
                :error-messages="errorMessages.quantity"
                :rules="[integerValidator]"
                :dense="false"
              ></v-text-field>
            </v-col>

            <!-- UM -->
            <v-col
              cols="12"
              xs="12"
              sm="6"
            >
              <v-select
                v-model="campaignLocal.um"
                :items="['Kg', 'Tm']"
                :label="$t('CampaignComposerWizard.body.tab1.form.um')"
                :placeholder="$t('CampaignComposerWizard.body.tab1.form.um_placeholder')"
                clearable
                hide-details="auto"
                :error-messages="errorMessages.um"
                :rules="rules.select"
                outlined
                :dense="false"
              />
            </v-col>

            <!-- Price -->
            <v-col
              cols="12"
              xs="12"
              sm="6"
            >
              <v-text-field
                v-model="campaignLocal.price"
                outlined
                :label="$t('CampaignComposerWizard.body.tab1.form.price')"
                clearable
                hide-details="auto"
                :error-messages="errorMessages.price"
                :rules="[required]"
                :dense="false"
              ></v-text-field>
            </v-col>

            <!-- Currency -->
            <v-col
              cols="12"
              xs="12"
              sm="6"
            >
              <v-select
                v-model="campaignLocal.currency"
                :items="['EUR', '$']"
                :label="$t('CampaignComposerWizard.body.tab1.form.currency')"
                :placeholder="$t('CampaignComposerWizard.body.tab1.form.currency_placeholder')"
                clearable
                hide-details="auto"
                :error-messages="errorMessages.currency"
                :rules="rules.select"
                outlined
                :dense="false"
              />
            </v-col>

            <v-col
              cols="12"
              xs="12"
              sm="6"
            >
            </v-col>
          </v-row>

          <!-- Subject and text -->
          <v-divider></v-divider>
          <div>
            <!-- Field: Subject -->
            <v-text-field
              v-model="campaignLocal.subject"
              :label="$t('CampaignComposerWizard.body.tab1.form.subject')"
              clearable
              hide-details="auto"
              solo
              flat
              class="mb-1 pt-0"
              :error-messages="errorMessages.subject"
              :rules="[required]"
            >
              <!-- Slot: Prepend Inner -->
              <!-- <template #prepend-inner>
                <span class="text--primary">Subject:</span>
              </template> -->
            </v-text-field>

            <v-divider></v-divider>

            <!-- Field: Message -->
            <v-textarea
              v-model="campaignLocal.message"
              :label="$t('CampaignComposerWizard.body.tab1.form.message')"
              solo
              flat
              clearable
              hide-details="auto"
              auto-grow
              class="campaign-compose-message"
              :error-messages="errorMessages.message"
              :rules="[required]"
            >
            </v-textarea>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  required,
  integerValidator,
} from '@core/utils/validation'
import { ref, watch } from '@vue/composition-api'

// Components

// Services
import useProductSupplier from '@/services/api/modules/productSupplier'

export default {
  components: {
  },
  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // props passed to setup function is reactive object (made probably by reactive()), it's properties are getters.
    // Watching a getter: https://v3.vuejs.org/api/computed-watch-api.html#watching-a-single-source
    const campaignLocal = ref(JSON.parse(JSON.stringify(props.campaign)))
    watch(() => props.campaign, () => {
      campaignLocal.value = props.campaign

      // debugger
      // eslint-disable-next-line no-use-before-define
      // form.value.reset()
      // eslint-disable-next-line no-use-before-define
      errorMessages.value = []
    })
    const errorMessages = ref([])

    // Form Ref
    const form = ref(null)
    const validationForm = () => new Promise((resolve, reject) => {
      const isFormValid = form.value.validate()
      if (!isFormValid) return reject()

      emit('update:campaign', campaignLocal.value) // ok

      return resolve(true)
    })

    const productSupplierFilterOptions = ref([])
    const fetchProductSuppliers = () => {
      useProductSupplier.fetchProductSuppliers({
        status: 'active',
      })
        .then(response => {
          const { productSuppliers, total } = response.data
          console.log(total)

          if (productSuppliers) {
            productSupplierFilterOptions.value = productSuppliers
          } else {
            productSupplierFilterOptions.value = []
          }
        })
    }
    fetchProductSuppliers()

    return {
      // UI
      perfectScrollbarSettings,

      // Form validations
      required,
      integerValidator,
      rules: {
        select: [v => !!v || 'Item is required'],
        select2: [v => v.length > 0 || 'Item is required in select 2'],
      },
      form,
      validationForm, // Available from Wizard

      // Data
      campaignLocal,
      errorMessages,
      productSupplierFilterOptions,
    }
  },
}
</script>

<style lang="scss">
  .campaign-compose-card {
    .v-input {
      .v-input__prepend-inner,
      .v-input__append-inner {
        align-self: center;
      }
      .v-input__prepend-inner {
        @include ltr() {
          margin-left: 10px;
        }
        @include rtl() {
          margin-right: 10px;
        }
      }
      .v-input__append-inner {
        @include ltr() {
          margin-right: 10px;
        }
        @include rtl() {
          margin-left: 10px;
        }
      }
    }
    .campaign-compose-message {
      .v-input__slot {
        @include ltr() {
          padding-left: 23px !important;
        }
        @include rtl() {
          padding-right: 23px !important;
        }
      }
    }
  }
</style>
