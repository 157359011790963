<template>
  <div>
    <v-card
      flat
      class="rounded-0"
    >
      <v-card-text class="pa-0">
        <form-wizard
          ref="refFormWizard"
          :title="null"
          :subtitle="null"
          :color="appModeColor"
          layout="horizontal"
          shape="circle"
          class="campaign-composer-form-wizard steps-transparent"

          :next-button-text="$t('CampaignComposerWizard.footer.next')"
          :back-button-text="$t('CampaignComposerWizard.footer.back')"
          :finish-button-text="$t('CampaignComposerWizard.footer.finish')"
          @on-complete="handleFormSubmit()"
        >
          <!-- Tab1: Personal data -->
          <!-- :before-change="validationFormPersonal" -->
          <!-- :before-change="validationFormTab1" -->
          <tab-content
            :title="$t('CampaignComposerWizard.body.tab1.menu')"
            :before-change="validationFormTab1"
          >
            <!-- ref="tab1" -->
            <CampaignComposerStep1Content
              ref="refFormWizardTab1"
              :campaign.sync="campaignLocal"
              @next-step="formWizardNextStep"
            />
          </tab-content>

          <!-- Recipients -->
          <!-- :before-change="validationFormTab2" -->
          <tab-content
            :title="$t('CampaignComposerWizard.body.tab2.menu')"
            :before-change="validationFormTab2"
          >
            <CampaignComposerStep2Recipients
              ref="refFormWizardTab2"
              :campaign.sync="campaignLocal"
              :options="campaignLocal.recipientsOptions"
            />
          </tab-content>

          <template
            slot="footer"
            slot-scope="props"
          >
            <div class="wizard-footer-left">
              <wizard-button
                v-if="props.activeTabIndex > 0"
                :style="props.fillButtonStyle"
                @click.native="props.prevTab()"
              >
                {{ $t('CampaignComposerWizard.footer.back') }}
              </wizard-button>
            </div>
            <div class="wizard-footer-right">
              <wizard-button
                v-if="!props.isLastStep"
                class="wizard-footer-right"
                :style="props.fillButtonStyle"
                @click.native="props.nextTab()"
              >
                {{ $t('CampaignComposerWizard.footer.next') }}
              </wizard-button>
              <!-- Deshabilitamos para que lo indique en botones externos -->
              <!-- <wizard-button
                v-if="props.isLastStep"
                class="wizard-footer-right"
                :style="props.fillButtonStyle"
                @click.native="props.nextTab()"
              >
                {{ $t('CampaignComposerWizard.footer.finish') }}
              </wizard-button> -->
            </div>
          </template>
        </form-wizard>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { toRefs } from '@vue/composition-api'
import themeConfig from '@themeConfig'

// Components
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

// UI Services Custom
import useAppConfigUser from '@/services/app/useAppConfigUser'

// Tab Components
import CampaignComposerStep1Content from './CampaignComposerStep1Content.vue'
import CampaignComposerStep2Recipients from './CampaignComposerStep2Recipients.vue'

// Services
// import useMarketing from '../useMarketing'
import useCampaignComposerHandler from './useCampaignComposerHandler'

export default {
  components: {
    // 3rd Party
    FormWizard,
    TabContent,
    WizardButton,

    CampaignComposerStep1Content,
    CampaignComposerStep2Recipients,
  },
  model: {
    prop: 'isCampaignComposerDialogActive',
    event: 'update:is-campaign-composer-dialog-active',
  },
  props: {
    isCampaignComposerDialogActive: {
      type: Boolean,
      required: false,
    },
    campaign: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // const { resolveCampaignStatusVariantAndIcon, CAMPAIGN_STATUS } = useMarketing()
    const { appModeColor } = useAppConfigUser()

    const {
      campaignLocal,
      resetCampaignLocal,

      // Form Wizard
      refFormWizard,
      refFormWizardTab1,
      refFormWizardTab2,
      formWizardNextStep,
      validationFormTab1,
      validationFormTab2,

      // UI
      outsideHandleFormSubmit, // Submit from outside the form (Force call validations)
      handleFormSubmit,
      onDiscard,
      formSubmitted,
    } = useCampaignComposerHandler(toRefs(props), emit)

    // Desde el contenedor podemos Guardar + Form validations
    const onOutsideCampaignSaveAsDraft = () => {
      outsideHandleFormSubmit('draft')
    }
    const onOutsideCampaignSaveAsPublished = () => {
      outsideHandleFormSubmit('published')
    }

    return {
      // Campaign control
      // CAMPAIGN_STATUS,
      campaignLocal,
      resetCampaignLocal,
      handleFormSubmit,
      onDiscard,
      formSubmitted,

      // Form wizard
      refFormWizard,
      refFormWizardTab1,
      refFormWizardTab2,
      formWizardNextStep,
      validationFormTab1,
      validationFormTab2,

      // UI
      // resolveCampaignStatusVariantAndIcon,
      perfectScrollbarSettings,
      appModeColor,
      appColorAccent: themeConfig.themes.light.accent,

      // Outside events
      onOutsideCampaignSaveAsDraft,
      onOutsideCampaignSaveAsPublished,
    }
  },
}
</script>

<style lang="scss">
  // @import '@core/scss/vue/libs/vue-wizard.scss';
  // @import '~@core/scss/base/pages/app-ecommerce.scss';
  // @import '~vue-form-wizard/dist/vue-form-wizard.min.css';
</style>

<style lang="scss" scoped>
  .campaign-composer-form-wizard ::v-deep {
      .wizard-tab-content {
          box-shadow: none !important;
          background: transparent !important;
          padding: 0;
      }
  }
</style>
