<template>
  <v-card
    class="rounded-0"
    outlined
  >
    <v-card-title>
      <!-- {{ $t('CompanySettings.body.tab4.about.title') }} -->
      Content
    </v-card-title>

    <!-- <v-card-text>
      <div
        v-for="(data, key, index) in contentData"
        :key="index"
        :class="index == 0 ? 'mt-2' : 'mt-1'"
      >
        <h5 class="text-capitalize mb-75">
          {{ key }}
        </h5>
        <span>{{ renderObject(key, data) }}</span>
      </div>
    </v-card-text> -->

    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th>
                KEY
              </th>
              <th>
                VALUE
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(data, key, index) in contentData"
              :key="index"
            >
              <td class="text-no-wrap">
                {{ key }}
              </td>
              <td class="text-no-wrap">
                {{ renderObject(key, data) }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  props: {
    contentData: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const renderObject = (key, data) => {
      if (data instanceof Object) {
        if (key === 'supplier') {
          return data.user.firstName
        }
        if (key === 'contacts') {
          return data.map(contact => `${contact.contact?.firstName} ${contact.company?.general?.name}`)
        }

        if (key === 'productSupplier') {
          return data.name
        }

        return data.id
      }

      return data
    }

    return {
      renderObject,
    }
  },
}
</script>
