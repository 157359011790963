import { ref, watch } from '@vue/composition-api'

// Dependencies
import useCampaign from '@/services/api/modules/campaign'
import { CAMPAIGN_STATUS } from '@/services/master/CampaignStatus'
import useCampaignHandler from '../campaign/useCampaignHandler'

export default function useCampaignComposerHandler(props, emit) {
  const { sendCampaignNotification } = useCampaignHandler()

  // ------------------------------------------------
  // campaignLocal
  // ------------------------------------------------
  const blankCampaign = {
    id: null,
    status: CAMPAIGN_STATUS.DRAFT,
    type: 'blind_scan',

    name: '',
    productSupplier: null,
    quantity: null,
    um: null,
    price: '',
    currency: 'EUR',

    subject: '',
    message: '',
    createdAt: new Date(),

    // Object complete
    supplier: null, // Asignado por el servidor
  }

  // To view converter .....
  const converter = object => {
    let campaignDataInit = JSON.parse(JSON.stringify(object)) // Clone

    // Overwrite base data
    if (!campaignDataInit?.id) {
      campaignDataInit = Object.assign(campaignDataInit, blankCampaign) // Overwrite
    }

    if (!campaignDataInit.recipientsOptions) {
      campaignDataInit = Object.assign(campaignDataInit, {
        recipientsOptions: {
          // type: campaignDataInit.type || 'blind_scan',
          recipientsInternal: campaignDataInit?.contactIds || [],
          recipientsBlindScan: {
            country: campaignDataInit?.country,
          },
        },
      })
    }

    return campaignDataInit
  }

  const campaignLocal = ref(converter(props.campaign.value))
  const resetCampaignLocal = () => {
    campaignLocal.value = converter(props.campaign.value)
  }
  watch(props.campaign, () => {
    resetCampaignLocal()

    // debugger
    // if (!props.campaign?.id) {
    // eslint-disable-next-line no-use-before-define
    // refFormWizard.value.reset()
    // }
  })

  // Form Wizard
  const refFormWizard = ref(null)
  const formWizardNextStep = () => refFormWizard.value.nextTab()
  const formWizardReset = () => refFormWizard.value.reset()
  const formWizardActivateAll = () => refFormWizard.value.activateAll()

  // When Wizard is initializate for fisrt time
  watch(() => refFormWizard.value, () => {
    if (refFormWizard.value) {
      formWizardActivateAll()
    }
  })

  // Tab1 ref (Access to validate form)
  const refFormWizardTab1 = ref(null)
  const validationFormTab1 = () => refFormWizardTab1.value.validationForm() // Must exist on Tab1

  const refFormWizardTab2 = ref(null)
  const validationFormTab2 = saveMode => refFormWizardTab2.value.validationForm(saveMode) // Must exist on Tab2

  // ------------------------------------------------
  // isEventHandlerSidebarActive
  // * Clear form if sidebar is closed
  // ! We can hide it using @hidden event
  // ------------------------------------------------
  // watch(props.isEventHandlerSidebarActive, val => {
  //   // ? Don't reset event till transition is finished
  //   if (!val) {
  //     setTimeout(() => {
  //       clearForm.value()
  //     }, 350)
  //   }
  // })

  // Manual submit (Outside form)
  const outsideHandleFormSubmit = (saveMode = CAMPAIGN_STATUS.DRAFT) => {
    validationFormTab1()
      .then(() => {
        validationFormTab2(saveMode)
          .then(() => {
            // eslint-disable-next-line no-use-before-define
            formSubmitted(saveMode)
          })
      })
  }

  const handleFormSubmit = (saveMode = CAMPAIGN_STATUS.DRAFT) => {
    // eslint-disable-next-line no-use-before-define
    formSubmitted(saveMode)
  }

  //  TODO - Not in use
  const onDiscard = () => {
    campaignLocal.value = {} // Esto no deberia hacer falta porque ya inicializamos al preparar
    // emit('on-close')
  }

  const formSubmitted = (saveMode = CAMPAIGN_STATUS.DRAFT) => {
    let status = saveMode
    if (saveMode === 'schedule') {
      status = CAMPAIGN_STATUS.DRAFT
    }

    // Clone and prepare object
    let campaignData = JSON.parse(JSON.stringify(campaignLocal.value)) // Clone

    // Replace Objects by id (They are mandatory but we also verify for null)
    campaignData = Object.assign(campaignData, {
      supplierId: campaignData.supplier?.id, // No influye
      productSupplierId: campaignData.productSupplier?.id,
      status,
      country: campaignData.recipientsOptions?.recipientsBlindScan?.country,

      // contactIds: campaignData.recipientsOptions?.recipientsInternal?.map(item => item.id),
      contactIds: campaignData.recipientsOptions?.recipientsInternal,
    })
    delete campaignData.supplier // No influye
    delete campaignData.productSupplier

    // API call (Create or Update)
    if (campaignData.id == null) {
      useCampaign.createCampaign(campaignData)
        .then(response => {
          const { campaign } = response.data
          if (campaign) {
            // emit('update:is-campaign-composer-active', false)
            // emit('update:campaign', campaign) // Create message and reload
            emit('on-save', campaign)
          }
        })
        .catch(error => {
          // Checking... si es un error de dominio lo pasamos al formulario
          if (error.response && error.response.data && error.response.data.error) {
            // this.$refs.refTab1.$refs.refFormObserverStepContent.setErrors(error.response.data.error)
            // refFormWizardTab1.value.$refs.form.setErrors(error.response.data.error)

            // Set errores
            // errorMessages.value = error.response.data.error
            sendCampaignNotification(error.response.data?.message, 'error')

            // TODO - Si tiene error en recipients: [Error] -> Pasamos al 1, sino al 0
            //    Tab 0 - Contenido
            //    Tab 1 - Recipients
            refFormWizard.value.changeTab(1, 0) // Tab1 >>> Tab0
          }
        })
    } else {
      useCampaign.updateCampaign(campaignData)
        .then(response => {
          const { campaign } = response.data
          if (campaign) {
            // emit('update:is-campaign-composer-active', false)
            // emit('update:campaign', campaign) // Create message and reload
            emit('on-save', campaign)
          }
        })
        .catch(error => {
          // Checking... si es un error de dominio lo pasamos al formulario
          if (error.response && error.response.data && error.response.data.error) {
            // refFormWizardTab1.value.$refs.form.setErrors(error.response.data.error)

            // Set errores
            // errorMessages.value = error.response.data.error
            sendCampaignNotification(error.response.data?.message, 'error')

            // TODO - Si tiene error en recipients: [Error] -> Pasamos al 1, sino al 0
            refFormWizard.value.changeTab(1, 0) // Tab1 >>> Tab0
          }
        })
    }
  }

  return {
    campaignLocal,
    resetCampaignLocal,

    // Form Wizard
    refFormWizard,
    refFormWizardTab1,
    refFormWizardTab2,
    validationFormTab1,
    validationFormTab2,

    // Form Wizard actions
    formWizardNextStep, // refFormWizard.next
    formWizardReset, // refFormWizard.reset

    // Actions
    outsideHandleFormSubmit,
    handleFormSubmit,
    onDiscard,
    formSubmitted,
  }
}
