<template>
  <div class="h-full">
    <!-- Combine 2 actions -->
    <div class="d-flex align-center justify-center pa-5">
      <!-- Top Action: Navigate to ProductSupplierList -->
      <div class="pa-5-kk">
        <v-list-item
          link
          :to="menuItemTopAction.route"
        >
          <!-- block -->
          <v-btn
            small
            outlined
            color="primary"
          >
            <v-icon size="22">
              {{ menuItemTopAction.icon }}
            </v-icon>
            <span class="ml-2">{{ menuItemTopAction.title }}</span>
          </v-btn>
        </v-list-item>
      </div>

      <!-- Top Action: Compose new Campaign -->
      <!-- <CampaignDialog
        :id="null"
        v-model="isComposeDialogVisible"
        :title="$t('MarketplaceProductSupplierDialog.title')"
        :text="$t('MarketplaceProductSupplierDialog.text')"
        :button="$t('MarketplaceProductSupplierDialog.button_text')"
        @connect="(id) => onCampaignCreate(id)"
      ></CampaignDialog> -->

      <!-- hide-overlay -->
      <!-- content-class="campaign-compose-dialog ms-md-auto" -->
      <!-- <v-dialog
        v-model="isComposeDialogVisible"
        width="700"
        :fullscreen="isComposeDialogFullscreen"
        transition="slide-y-reverse-transition"
      >
        <template #activator="{ on, attrs }">
          <div class="pa-5-kk">
            <v-btn
              small
              :block="false"
              outlined
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="isComposeDialogVisible = true"
            >
              <v-icon size="22">
                {{ icons.mdiPlus }}
              </v-icon>
              <span class="ml-2">Campaign2</span>
            </v-btn>
          </div>
        </template>

        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            jajajaj
          </v-card-title>
        </v-card>
      </v-dialog> -->
    </div>
    <!-- Scrollable Area -->
    <perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="ps-campaign-left-sidebar"
    >
      <v-list
        dense
        flat
      >
        <!-- :to="filter.route" -->
        <!-- exact-active-class="primary--text v-list-item--exact-active" -->
        <v-list-item
          v-for="filter in campaignFilters"
          :key="filter.title"
          :class="filter.route && isDynamicRoutePathActive(filter.route) ? 'v-list-item--exact-active' : ''"
          @click="redirectWithReload(filter.route)"
        >
          <v-list-item-icon>
            <v-icon size="20">
              {{ filter.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-base">
              {{ filter.title }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action
            v-if="campaignsMeta[filter.title.toLowerCase()]"
            class="ma-0"
          >
            <v-badge
              :color="filter.color"
              inline
              :content="campaignsMeta[filter.title.toLowerCase()]"
              class="number-badge v-badge-light-bg"
              :class="`${filter.color}--text`"
            >
            </v-badge>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <p class="text-xs text--disabled mt-8 mx-5 mb-1">
        LABELS
      </p>
      <v-list
        dense
        flat
      >
        <!-- :to="label.route" -->
        <!-- exact-active-class="primary--text v-list-item--exact-active" -->
        <v-list-item
          v-for="label in campaignLabels"
          :key="label.title"
          :class="label.route && isDynamicRoutePathActive(label.route) ? 'primary--text v-list-item--exact-active' : ''"
          @click="redirectWithReload(label.route)"
        >
          <v-list-item-icon>
            <v-badge
              :color="label.color"
              dot
              inline
              class="align-self-center label-dot"
            ></v-badge>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-base">
              {{ label.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </perfect-scrollbar>
  </div>
</template>

<script>
import { mdiPlus, mdiSendCheckOutline, mdiSendOutline, mdiSquareEditOutline, mdiStarOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

import useRouterPath from '@/router/useRouterPath'
import { isDynamicRoutePathActive, redirectWithReload } from '@core/utils'

// Local Components
// import CampaignComposeContent from './CampaignComposeContent.vue'
import CampaignDialog from '../campaign-composer/CampaignComposerDialog.vue'

export default {
  components: {
    // Local Components
    // CampaignComposeContent,

    // eslint-disable-next-line vue/no-unused-components
    CampaignDialog,

    // 3rd Party
    PerfectScrollbar,
  },
  props: {
    isCampaignComposeDialogVisible: {
      type: Boolean,
      required: true,
    },
    campaignsMeta: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { menuItemProductSupplierActive: menuItemTopAction } = useRouterPath()

    // Compose Dialog
    const isComposeDialogVisible = ref(false)
    const isComposeDialogFullscreen = ref(false)

    const campaignFilters = [
      // { title: 'Published', icon: mdiSendOutline, to: { name: 'apps-campaign-folder' } },
      {
        title: 'Published',
        color: 'success',
        icon: mdiSendOutline,
        route: { name: 'apps-campaign-folder', params: { folder: 'published' } },
      },
      {
        title: 'Draft',
        color: 'warning',
        icon: mdiSquareEditOutline,
        route: { name: 'apps-campaign-folder', params: { folder: 'draft' } },
      },
      {
        title: 'Finished',
        color: 'primary',
        icon: mdiSendCheckOutline,
        route: { name: 'apps-campaign-folder', params: { folder: 'finished' } },
      },
      {
        title: '',
        color: '',
        icon: '',
        route: undefined, // { name: '' },
      },
      {
        title: 'Starred',
        color: 'warning',
        icon: mdiStarOutline,
        route: { name: 'apps-campaign-folder', params: { folder: 'starred' } },
      },

      // {
      //   title: 'Trash',
      //   color: 'error',
      //   icon: mdiTrashCanOutline,
      //   route: { name: 'apps-campaign-folder', params: { folder: 'trash' } },
      // },
    ]

    const campaignLabels = [
      { title: 'Personal', color: 'success', route: { name: 'apps-campaign-label', params: { label: 'personal' } } },
      { title: 'Company', color: 'primary', route: { name: 'apps-campaign-label', params: { label: 'company' } } },
      { title: 'Important', color: 'warning', route: { name: 'apps-campaign-label', params: { label: 'important' } } },
      { title: 'Private', color: 'error', route: { name: 'apps-campaign-label', params: { label: 'private' } } },
    ]

    // Perfect Scrollbar
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
      wheelSpeed: 0.7,
    }

    return {
      menuItemTopAction,

      // Compose Dialog
      isComposeDialogVisible,
      isComposeDialogFullscreen,

      // Filter & Labels
      campaignFilters,
      campaignLabels,

      // Perfect Scrollbar
      perfectScrollbarSettings,

      // Router
      isDynamicRoutePathActive,
      redirectWithReload,

      icons: {
        mdiPlus,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

.ps-campaign-left-sidebar {
  // height: calc(100% - 78px); // 78 = 20 + 38 + 20 (38-Boton y 20x2 de padding)
  // height: calc(100%);

  .v-list--dense .v-list-item {
    height: 38px;
    min-height: 38px;
    border-left: 8px solid transparent;

    &.v-list-item--exact-active {
      border-color: var(--v-primary-base);
    }

    .v-list-item__icon {
      align-items: center;
    }

    // label dot
    .label-dot {
      .v-badge__badge {
        width: 12px !important;
        height: 12px !important;
        border-radius: 10px;
      }
    }
  }
}

.campaign-compose-dialog {
  align-self: flex-end !important;
}

@include app-elevation(campaign-compose-dialog, 16);
</style>
