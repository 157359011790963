<template>
  <v-card>
    <v-card-text class="text-muted">
      Choose the country to find new contacts (Plan included)
    </v-card-text>

    <v-card-text>
      <v-form ref="form">
        <v-row>
          <v-col cols="12">
            <CountrySelect
              id="company-country"
              v-model="recipientsBlindScanLocal.country"
              :country-data="recipientsBlindScanLocal.country"
              :placeholder="$t('CampaignComposerWizard.body.tab2.form.recipients_blind_scan_placeholder')"
              :error-messages="errorMessagesLocal.country"
              :rules="[required]"
              clearable
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-card-text class="text-muted mt-25">
      <b>Buscamos afinidad:</b>
      <li>Pais</li>
      <li>Productos de interés</li>
    </v-card-text>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { required } from '@core/utils/validation'

// Components
import CountrySelect from '@/components/CountrySelect.vue'

export default {
  components: {
    CountrySelect,
  },
  props: {
    recipientsBlindScan: {
      type: Object,
      required: false,
      default: () => ({
        country: null,
      }),
    },
    errorMessages: {
      type: Array,
      required: false,
      default: () => ([]),
    },
  },
  setup(props, { emit }) {
    const recipientsBlindScanLocal = ref(props.recipientsBlindScan)
    watch(() => recipientsBlindScanLocal.value.country, () => {
      emit('update:recipientsBlindScan', recipientsBlindScanLocal.value)
    })

    const errorMessagesLocal = ref([])
    watch(() => props.errorMessages, () => {
      errorMessagesLocal.value = props.errorMessages
    })

    // TODO - No esta funcionando la validacion
    // Form validation
    const form = ref(null)
    const validationForm = () => {
      // const isFormValid = form.value.validate()
      const isFormValid = !!recipientsBlindScanLocal.value?.country
      if (isFormValid) {
        errorMessagesLocal.value = []
      } else {
        errorMessagesLocal.value = [{ country: 'Seleccione un pais' }]
      }

      return isFormValid
    }
    const validationFormPromise = () => new Promise((resolve, reject) => {
      const isFormValid = validationForm()
      if (!isFormValid) return reject()

      emit('update:recipientsBlindScan', recipientsBlindScanLocal.value) // ok

      return resolve(true)
    })

    return {
      recipientsBlindScanLocal,
      errorMessagesLocal,

      // Form Validators
      form,
      validationForm,
      validationFormPromise,
      required,
    }
  },
}
</script>
