<template>
  <div>
    <v-row>
      <!-- Left -->
      <v-col
        cols="12"
        xs="12"
        sm="6"
      >
        <v-card id="account-setting-card">
          <v-tabs
            v-model="tab"
            fixed-tabs
            :hide-slider="false"
            icons-and-text
            centered
            @change="(event) => onTabChange(event)"
          >
            <v-tab
              v-for="tab in tabs"
              :key="tab.title"
              center-active
            >
              <span class="d-none d-sm-block">{{ tab.title }}</span>
              <v-icon>
                {{ tab.icon }}
              </v-icon>
            </v-tab>
          </v-tabs>

          <!-- tabs item -->
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <CampaignComposerStepRecipientsInternal
                ref="refRecipientsInternal"
                :contact-ids.sync="campaignLocal.recipientsOptions.recipientsInternal"
              />
            </v-tab-item>

            <v-tab-item>
              <CampaignComposerStepRecipientsBlindScan
                ref="refRecipientsBlindScan"
                :recipients-blind-scan.sync="campaignLocal.recipientsOptions.recipientsBlindScan"
                @next-step="$emit('next-step')"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>

      <!-- Right -->
      <v-col
        cols="12"
        xs="12"
        sm="6"
      >
        <CampaignComposerGoalOverview
          :target-result="targetResultLocal"
          @on-calculate="onCalculateTarget"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  mdiAccountOutline, mdiLockOpenOutline,
} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

import { CAMPAIGN_STATUS } from '@/services/master/CampaignStatus'

// Services
import useCampaignHandler from '../campaign/useCampaignHandler'
import useCampaign from '@/services/api/modules/campaign'

// Components
import CampaignComposerStepRecipientsInternal from './CampaignComposerStepRecipientsInternal.vue'
import CampaignComposerStepRecipientsBlindScan from './CampaignComposerStepRecipientsBlindScan.vue'
import CampaignComposerGoalOverview from './CampaignComposerGoalOverview.vue'

export default {
  components: {
    CampaignComposerStepRecipientsInternal,
    CampaignComposerStepRecipientsBlindScan,
    CampaignComposerGoalOverview,
  },
  props: {
    options: {
      type: Object,
      required: false,
      default: () => ({
        type: null,
        recipientsInternal: [],
        recipientsBlindScan: {
          country: null,
        },
      }),
    },
    campaign: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { sendCampaignNotification } = useCampaignHandler()

    // Data
    const localOptions = ref(JSON.parse(JSON.stringify(props.options)))
    const campaignLocal = ref(JSON.parse(JSON.stringify(props.campaign)))
    watch(() => props.campaign, () => {
      campaignLocal.value = props.campaign

      // eslint-disable-next-line no-use-before-define
      setActiveTab(campaignLocal.value.type)
      // eslint-disable-next-line no-use-before-define
      onCalculateTarget()
    })

    // >>> Target Results series
    const targetResultLocal = ref({
      goal: 0,
      partialAffinity: 0,
      fullAffinity: 0,
    })

    const calculateBlindScan = () => {
      // Parameters
      const countryCode = campaignLocal.value?.recipientsOptions?.recipientsBlindScan?.country
      const productId = campaignLocal.value?.productSupplier?.product?.id
      const productConservationId = campaignLocal.value?.productSupplier?.productConservation?.id
      const productPresentationId = campaignLocal.value?.productSupplier?.productPresentation?.id
      if (!productId) {
        return
      }

      useCampaign.getBlindScanResult({
        productId,
        productConservationId,
        productPresentationId,
        countryCode,
      })
        .then(result => {
          const { targetResult } = result.data
          if (targetResult) {
            targetResultLocal.value = {
              goal: targetResult.goal,
              partialAffinity: targetResult.partialAffinity,
              fullAffinity: targetResult.fullAffinity,
            }
          }
        })
    }

    const calculateInternal = () => {
      // Parameters
      const productId = campaignLocal.value?.productSupplier?.product?.id
      const productConservationId = campaignLocal.value?.productSupplier?.productConservation?.id
      const productPresentationId = campaignLocal.value?.productSupplier?.productPresentation?.id
      if (!productId) {
        return
      }

      // Contactos internos (Id's)
      const contactIds = campaignLocal.value?.recipientsOptions?.recipientsInternal

      useCampaign.getInternalResult({
        productId,
        productConservationId,
        productPresentationId,
        contactIds,
      })
        .then(result => {
          const { targetResult } = result.data
          if (targetResult) {
            targetResultLocal.value = {
              goal: targetResult.goal,
              partialAffinity: targetResult.partialAffinity,
              fullAffinity: targetResult.fullAffinity,
            }
          }
        })
    }

    // Calculate Target result
    const onCalculateTarget = () => {
      if (campaignLocal.value.type === 'blind_scan') {
        calculateBlindScan()
      }
      if (campaignLocal.value.type === 'internal') {
        calculateInternal()
      }
    }

    // >>> Form
    // Form Validations (Inside Componens)
    const refRecipientsInternal = ref(null)
    const refRecipientsBlindScan = ref(null)

    const manualFormValidate = saveMode => {
      if (saveMode === CAMPAIGN_STATUS.DRAFT) {
        return true
      }

      // Se ha seleccionado algún contacto ?
      if (campaignLocal.value.type === 'internal') {
        // const isFormValid = (campaignLocal.value?.recipientsOptions?.recipientsInternal.length > 0)
        const isFormValid = refRecipientsInternal.value.validationForm()
        if (!isFormValid) {
          sendCampaignNotification('Internal: Sin contactos', 'error')
        }

        return isFormValid
      }

      // Validate: Se ha seleccionado un país ??
      if (campaignLocal.value.type === 'blind_scan') {
        // const isFormValid = !!campaignLocal.value?.recipientsOptions?.recipientsBlindScan?.country
        const isFormValid = refRecipientsBlindScan.value.validationForm()
        if (!isFormValid) {
          sendCampaignNotification('Blind Scan: Indique país', 'error')
        }

        return isFormValid
      }

      return false
    }

    const validationForm = (saveMode = CAMPAIGN_STATUS.DRAFT) => new Promise((resolve, reject) => {
      const isFormValid = manualFormValidate(saveMode) // form.value.validate()
      if (!isFormValid) return reject()

      emit('update:options', localOptions.value) // ok
      emit('update:campaign', campaignLocal.value) // ok

      return resolve(true)
    })

    // >>> Tabs
    const tab = ref(0)
    const tabs = [
      { title: 'Internal', icon: mdiAccountOutline },
      { title: 'Blind Scan', icon: mdiLockOpenOutline },
    ]
    const onTabChange = event => {
      campaignLocal.value.type = event === 0 ? 'internal' : 'blind_scan'
    }

    const setActiveTab = type => {
      tab.value = (type === 'blind_scan') ? 1 : 0
    }
    setActiveTab(campaignLocal.value.type)

    return {
      targetResultLocal,
      onCalculateTarget,

      // Tab control
      tab,
      tabs,
      onTabChange,

      // Form validations
      refRecipientsInternal,
      refRecipientsBlindScan,
      validationForm, // Available from Wizard

      // Data
      localOptions,
      campaignLocal,
    }
  },
}
</script>
