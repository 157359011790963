<template>
  <div class="h-full">
    <!-- 1st Row -->
    <div class="d-flex align-center px-sm-6 px-4 py-2">
      <div
        class="d-flex align-center cursor-pointer mr-4"
        @click="$emit('close-campaign-view')"
      >
        <!-- Back Button -->
        <v-btn
          icon
          small
          class="me-2"
        >
          <v-icon size="28">
            {{ icons.mdiChevronLeft }}
          </v-icon>
        </v-btn>

        <!-- Subject -->
        <h1 class="font-weight-medium text-base me-2 text-truncate">
          {{ campaignView.subject }}
        </h1>
      </div>

      <!-- Labels -->
      <v-badge
        v-for="label in campaignView.labels"
        :key="label"
        :color="resolveCampaignLabelColor(label)"
        inline
        :content="label"
        class="email-label-chip text-capitalize v-badge-light-bg"
        :class="`${resolveCampaignLabelColor(label)}--text`"
      >
      </v-badge>

      <!-- Navigation Prev Vs Next -->
      <v-spacer></v-spacer>
      <v-btn
        icon
        small
        :disabled="!openItemMeta.hasPrev"
        @click="$emit('change-open-campaign', 'prev')"
      >
        <v-icon size="28">
          {{ icons.mdiChevronLeft }}
        </v-icon>
      </v-btn>
      <v-btn
        icon
        small
        :disabled="!openItemMeta.hasNext"
        @click="$emit('change-open-campaign', 'next')"
      >
        <v-icon size="28">
          {{ icons.mdiChevronRight }}
        </v-icon>
      </v-btn>
    </div>

    <!-- 2nd Row -->
    <v-divider></v-divider>
    <div class="d-flex align-center px-sm-6 px-4 py-2">
      <!-- Actions: Send trash (Only !== trash) -->
      <!-- <v-btn
        v-show="$route.params.folder !== 'trash'"
        icon
        small
        class="me-2"
        @click="$emit('move-campaign-to-folder', 'trash')"
      >
        <v-icon size="22">
          {{ icons.mdiTrashCanOutline }}
        </v-icon>
      </v-btn> -->

      <!-- Actions: Set labels to ['Personal', 'Company', ...] -->
      <MenuLabels
        :is-visible="true"
        :update-label-menu-list-items="updateLabelMenuListItems"
        @on-label-click="(value) => $emit('update-campaign-label', value)"
      ></MenuLabels>

      <v-spacer></v-spacer>

      <!-- Other actions -->
      <div class="d-flex align-center">
        <!-- :color="resolveCampaignStatus(campaignView.status).color" -->
        <v-badge
          inline
          class="email-label-chip text-capitalize v-badge-light-bg mr-4"
          :class="`${resolveCampaignStatus(campaignView.status).color}--text`"
          :content="campaignView.status"
        ></v-badge>

        <!-- Custom Actions: Published | Finished -->
        <!-- DRAFT: La puedo pubicar -->
        <v-btn
          v-if="campaignView.status === CAMPAIGN_STATUS.DRAFT"
          :icon="false"
          outlined
          small
          class="me-2"
          :color="customActionPublished.color"
          @click="$emit('set-campaign-action', customActionPublished.value)"
        >
          <v-icon size="22">
            {{ customActionPublished.icon }}
          </v-icon>
          <span class="pl-2">{{ customActionPublished.title }}</span>
        </v-btn>

        <!-- DRAFT: La puedo editar -->
        <v-btn
          v-if="campaignView.status === CAMPAIGN_STATUS.DRAFT"
          small
          outlined
          class="me-2"
          :color="customActionDraft.color"
          @click="$emit('on-edit', campaignView)"
        >
          <v-icon size="22">
            {{ customActionDraft.icon }}
          </v-icon>
          <span class="pl-2">{{ customActionDraft.title }}</span>
        </v-btn>

        <!-- PUBLISHED: La puedo finalizar -->
        <v-btn
          v-if="campaignView.status === CAMPAIGN_STATUS.PUBLISHED"
          icon
          small
          class="me-2"
          :color="customActionFinished.color"
          @click="$emit('set-campaign-action', customActionFinished.value)"
        >
          <v-icon size="22">
            {{ customActionFinished.icon }}
          </v-icon>
        </v-btn>

        <!-- Draft: DELETE -->
        <v-btn
          v-if="campaignView.status === CAMPAIGN_STATUS.DRAFT || campaignView.status === CAMPAIGN_STATUS.FINISHED"
          icon
          small
          outlined
          class="ml-2"
          color="error"
          @click="$emit('on-delete', campaignView)"
        >
          <v-icon size="22">
            {{ icons.mdiTrashCanOutline }}
          </v-icon>
        </v-btn>
      </div>
    </div>

    <v-divider></v-divider>
    <perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="ps-campaign-view-content"
    >
      <!-- Campaign Messages -->
      <div
        class="position-relative ma-sm-7 ma-4"
      >
        <!-- <campaign-message-card
          :message="campaignView"
          style="top:0;z-index:10"
        /> -->

        <!-- Cards: Supplier(Me) and ProductSupplier(My product) -->
        <v-card
          outlined
          class="mt-4-"
          style="top:0;z-index:10"
        >
          <v-card-text class="d-flex flex-wrap align-center pa-4">
            <v-btn
              v-if="$vuetify.breakpoint.smAndUp"
              icon
              small
              class="me-2"
              :color="campaignView.isStarred ? 'warning': null"
              @click.stop="$emit('toggle-campaign-starred', campaignView)"
            >
              <v-icon>
                {{ campaignView.isStarred ? icons.mdiStar : icons.mdiStarOutline }}
              </v-icon>
            </v-btn>
            <!-- Supplier(Me) ProductSupplier(My product) -->
            <!-- <SupplierCard :supplier="campaignView.productSupplier.supplier" /> -->
            <ProductSupplierCard :product-supplier="campaignView.productSupplier" />

            <!-- Right -->
            <v-spacer></v-spacer>
            <span
              v-if="$vuetify.breakpoint.smAndUp"
              class="text--disabled text-xs me-2 text-no-wrap"
            >
              {{ formatDate(campaignView.createdAt, { dateStyle: 'long', timeStyle: 'short' }) }}
            </span>
            <div
              v-else
              class="d-flex flex-column align-center"
            >
              <v-btn
                icon
                small
                :color="campaignView.isStarred ? 'warning': null"
                @click.stop="$emit('toggle-campaign-starred', campaignView)"
              >
                <v-icon>
                  {{ campaignView.isStarred ? icons.mdiStar : icons.mdiStarOutline }}
                </v-icon>
              </v-btn>
              <span class="text--disabled text-xs">{{ formatDateToMonthShort(campaignView.createdAt, { hour: 'numeric', minute: 'numeric', }) }}</span>
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text class="d-flex align-center">
            <h1 class="font-weight-medium text-base me-2 text-truncate">
              {{ campaignView.subject }}
            </h1>
            <v-badge
              color="secondary"
              inline
              :content="campaignView.type"
            ></v-badge>
            <v-badge
              v-if="campaignView.type === 'internal'"
              color="secondary"
              inline
              :content="campaignView.contactIds.length"
            ></v-badge>
            <v-badge
              v-if="campaignView.type === 'blind_scan'"
              color="secondary"
              inline
              :content="campaignView.country"
            ></v-badge>
          </v-card-text>
          <!-- Message -->
          <!-- eslint-disable vue/no-v-html -->
          <v-card-text
            v-html="campaignView.message"
          ></v-card-text>
        </v-card>

        <!-- Content -->
        <v-row class="mt-4">
          <v-col
            cols="12"
            lg="6"
            md="6"
            order="2"
          >
            <CampaignContent :content-data="campaignView"></CampaignContent>
          </v-col>
          <v-col
            v-if="campaignView.type === 'internal'"
            cols="12"
            lg="6"
            md="6"
            order="1"
          >
            <v-card
              class="rounded-0"
              outlined
            >
              <v-card-title>
                Contacts (Internal)
              </v-card-title>

              <v-card-text>
                <div
                  v-for="(contact, index) in campaignView.contacts"
                  :key="index"
                >
                  <div class="d-flex align-center">
                    <contact-card
                      :contact="contact"
                      :big="false"
                    ></contact-card>

                    <v-spacer></v-spacer>
                    <contact-chat-message-navegable
                      :contact="contact"
                      :is-navegable="true"
                    ></contact-chat-message-navegable>
                  </div>
                  <v-divider
                    v-if="index != campaignView.contacts.length - 1"
                    class="my-1"
                  ></v-divider>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import { formatDate, formatDateToMonthShort } from '@core/utils/filter'
import {
  mdiChevronLeft, mdiChevronRight, mdiStar, mdiStarOutline, mdiTrashCanOutline,
} from '@mdi/js'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import useCampaignHandler from './useCampaignHandler'

// Local Components
// import CampaignViewMessageCard from './CampaignViewMessageCard.vue'
import ContactCard from '@/components/Cards/ContactCard.vue'
import ProductSupplierCard from '@/components/Cards/ProductSupplierCard.vue'
import SupplierCard from '@/components/Cards/SupplierCard.vue'
import MenuLabels from '@/components/MenuLabels.vue'
import ContactChatMessageNavegable from '@/components/NavegableIcon/ContactChatMessageNavegable.vue'
import CampaignContent from './CampaignContent.vue'

export default {
  components: {
    PerfectScrollbar,

    // Local Components
    ProductSupplierCard,
    // eslint-disable-next-line vue/no-unused-components
    SupplierCard,
    CampaignContent,
    MenuLabels,

    //
    ContactCard,
    ContactChatMessageNavegable,
  },
  props: {
    campaignView: {
      type: Object,
      required: true,
    },
    openItemMeta: {
      type: Object,
      required: true,
    },
  },
  setup() {
    // ————————————————————————————————————
    //* ——— useCampaign
    // ————————————————————————————————————
    const {
      resolveCampaignLabelColor,
      resolveCampaignStatus,
      customActionPublished,
      customActionFinished,
      customActionDraft,
      updateLabelMenuListItems,
      CAMPAIGN_STATUS,
    } = useCampaignHandler()

    // ————————————————————————————————————
    //* ——— Perfect Scrollbar
    // ————————————————————————————————————
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      // useCampaign
      resolveCampaignLabelColor,
      resolveCampaignStatus,
      customActionPublished,
      customActionFinished,
      customActionDraft,
      updateLabelMenuListItems,
      CAMPAIGN_STATUS,

      // Perfect Scrollbar
      perfectScrollbarSettings,

      // Icons
      icons: {
        mdiChevronLeft,
        mdiChevronRight,
        mdiTrashCanOutline,
        mdiStarOutline,
        mdiStar,
      },
      formatDate,
      formatDateToMonthShort,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';
// @import '~vuetify/src/components/VCard/_variables.scss';

@include theme--child(ps-campaign-view-content) using ($material) {
  background-color: rgba(map-deep-get($material, 'primary-shade'), 0.04);
}

.ps-campaign-view-content {
  height: calc(100% - 46px - 46px - 2px);

  // .view-all-toggler {
  //   &:hover {
  //     color: var(--v-primary-base) !important;
  //   }
  // }
}
</style>
