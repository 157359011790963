<template>
  <!-- v-model="isDialogActive" -->
  <v-dialog
    :value="isDialogActive"
    width="500"
    scrollable
    @click:outside="$emit('update:is-dialog-active',false)"
  >
    <!-- Esta plantilla es para activar el dialog -->
    <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Click Me
        </v-btn>
      </template> -->

    <v-card>
      <!-- Toolbar -->
      <v-toolbar
        dark
      >
        <v-btn
          icon
          dark
          @click="$emit('update:is-dialog-active',false);"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
        <v-toolbar-title>Publish Campaign</v-toolbar-title>

        <!-- <v-spacer></v-spacer>
        <v-btn
          :icon="false"
          outlined
          small
          class="me-2"
          :color="customActionPublished.color"
          @click="onOutsideCampaignSaveAsPublished()"
        >
          <v-icon size="22">
            {{ customActionPublished.icon }}
          </v-icon>
          <span class="pl-2">{{ customActionPublished.title }}</span>
        </v-btn> -->
      </v-toolbar>

      <v-card-text class="pa-2 pa-md-5">
        <CampaignSummary :campaign="campaignLocal"></CampaignSummary>
      </v-card-text>

      <!-- Foooter: Cancel + Published -->
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          text
          @click="$emit('update:is-dialog-active',false)"
        >
          <v-icon>
            {{ icons.mdiClose }}
          </v-icon>
          <span class="ml-1">{{ buttonTextCancel }}</span>
        </v-btn>

        <v-btn
          :icon="false"
          outlined
          small
          class="me-2"
          :color="customActionPublished.color"
          @click="$emit('update:is-dialog-active',false); $emit('ok', campaignLocal)"
        >
          <v-icon size="22">
            {{ customActionPublished.icon }}
          </v-icon>
          <span class="pl-2">{{ customActionPublished.title }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose, mdiEmailSendOutline } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

// Services
import useCampaignHandler from './useCampaignHandler'

// Components
import CampaignSummary from './CampaignSummary.vue'

export default {
  components: {
    CampaignSummary,
  },
  model: {
    prop: 'isDialogActive',
    event: 'update:is-dialog-active',
  },
  props: {
    isDialogActive: {
      type: Boolean,
      required: true,
    },
    campaign: {
      type: Object,
      default: () => {},
    },
    buttonText: {
      type: String,
      default: 'Publish',
    },
    buttonTextCancel: {
      type: String,
      default: 'Cancel',
    },
  },
  setup(props, { emit }) {
    const { customActionPublished } = useCampaignHandler()

    const campaignLocal = ref(props.campaign)
    watch(() => props.campaign, () => {
      campaignLocal.value = props.campaign
    })

    const onCampaignPublishedConfirmed = () => {
      emit('connect', props.id)
    }

    return {
      campaignLocal,
      onCampaignPublishedConfirmed,

      // UI
      customActionPublished,
      icons: {
        mdiClose,
        mdiEmailSendOutline,
      },
    }
  },
}
</script>
