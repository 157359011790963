<template>
  <UserCard
    :user="contact.userContact"
    :company="contact.company"
    :big="big"
  ></UserCard>
</template>
<script>

import UserCard from '@/components/Cards/UserCard.vue'

export default {
  components: {
    UserCard,
  },
  props: {
    contact: {
      type: Object,
      required: true,
    },
    big: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
