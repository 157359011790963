import axios from '@axios'

// import BaseApiService from '../apiBase'

// interface CampaignInterface {
//     id: string;
//     name: string;
// }

// Campaign Router list
// GET    /marketing/campaigns             Retrieves the collection of Campaign resources.
// POST   /marketing/campaigns             Creates a Campaign resource.
// GET    /marketing/campaigns/{id}        Retrieves a Campaign resource.
// PUT    /marketing/campaigns/{id}        Replaces a Campaign resource.
// DELETE /marketing/campaigns/{id}        Removes a Campaign resource.
//
// Other Actions
// POST   /marketing/campaigns/status/{id}  Change status  of a Campaign resource.
// POST   /marketing/campaigns/starred/{id} Change starred of a Campaign resource.
// POST   /marketing/campaigns/labels/{id}  Change label   of a Campaign resource.
const routeMarketingCampaigns = '/marketing/campaigns'

// URL connection
//  8060 -> symfony API
//  8062 -> @fake-db
const API_BASE_MODULE = process.env.VUE_APP_API_BASE

// const API_BASE_MODULE = '' // @fake-db
// const API_BASE_MODULE = undefined // Take default BaseApiService

// class Campaign extends BaseApiService { constructor() { super(axios)}
class Campaign {
  axiosIns = axios

  API_BASE = API_BASE_MODULE !== undefined ? API_BASE_MODULE : this.baseUrl

  fetchCampaigns(queryParams) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}${routeMarketingCampaigns}`, { params: queryParams })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  createCampaign(campaignData) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .post(`${this.API_BASE}${routeMarketingCampaigns}`, campaignData)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  fetchCampaign(id) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}${routeMarketingCampaigns}/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  updateCampaign(campaignData) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .put(`${this.API_BASE}${routeMarketingCampaigns}/${campaignData.id}`, { ...campaignData })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  removeCampaign(id) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .delete(`${this.API_BASE}${routeMarketingCampaigns}/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // *** Other actions //

  // { campaignId, status: [publish | withdraw] }
  setCampaignStatus(campaignId, newStatus) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .post(`${this.API_BASE}${routeMarketingCampaigns}/status/${campaignId}`, { status: newStatus })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // { campaignId, isStarred: [true | false] }
  changeCampaignStarred(campaignId, newIsStarred) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .post(`${this.API_BASE}${routeMarketingCampaigns}/starred/${campaignId}`, { isStarred: newIsStarred })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // { campaignId, label: ["important" | "company" ...] }
  changeCampaignLabels(campaignId, toggleLabel) {
    return new Promise((resolve, reject) => {
      this.axiosIns

        // .post('/marketing/campaign/update-label', payload)
        .post(`${this.API_BASE}${routeMarketingCampaigns}/labels/${campaignId}`, { label: toggleLabel })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  getBlindScanResult(args) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}${routeMarketingCampaigns}/blind_scan`, { params: { ...args } })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  getInternalResult(args) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}${routeMarketingCampaigns}/internal`, { params: { ...args } })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}

// Exportamos useCampaign (Antes se exportaba la clase y se definia un fichero nuevo para exportar la instancia)
const useCampaign = new Campaign()
export default useCampaign
