<template>
  <v-card>
    <v-card-text class="text-muted">
      Choose the contacts you wish send the campaign (Free)
    </v-card-text>

    <v-card-text>
      <v-form ref="form">
        <v-row>
          <v-col cols="12">
            <!-- Recipients -->
            <!-- item-value="contact.firstName" -->
            <!-- item-text="contact.firstName" -->
            <!-- return-object -->
            <v-autocomplete
              v-model="contactIdsLocal"
              :items="contactFilterOptions"
              multiple
              :label="$t('CampaignComposerWizard.body.tab2.form.recipients_internal')"
              :placeholder="$t('CampaignComposerWizard.body.tab2.form.recipients_internal_placeholder')"
              outlined
              item-text="id"
              :dense="false"
              clearable
              hide-details="auto"
              :rules="rules.select"
              :error-messages="errorMessagesLocal.contacts"
              @change="onSelectChange()"
            >
              <template v-slot:selection="data">
                <!-- @click="data.select" -->
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click:close="onRemoveContact(data.item)"
                >
                  <ContactCard :contact="data.item"></ContactCard>
                </v-chip>
              </template>

              <template v-slot:item="data">
                <template>
                  <v-list-item
                    v-bind="data.attrs"
                    style="height: unset;"
                    v-on="data.on"
                  >
                    <v-list-item-content>
                      <ContactCard :contact="data.item"></ContactCard>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'

// Components
import ContactCard from '@/components/Cards/ContactCard.vue'

// Services
import useContact from '@/services/api/modules/contact'

export default {
  components: {
    ContactCard,
  },
  props: {
    contactIds: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const contactIdsLocal = ref(props.contactIds)

    // Actualizamos cuando se produzcan diferencias
    const firstWatchInit = ref(false)
    watch(
      () => props.contactIds,
      () => {
        firstWatchInit.value = true
        contactIdsLocal.value = JSON.parse(JSON.stringify(props.contactIds))
      },
    ) // , { deep: true })

    // Observamos los cambios del array
    // No es necesario { deep: true } porque los cambios en el array son siempre de tamaño
    watch(
      () => contactIdsLocal.value,
      () => {
        // FirstWatchInit: Los cambios son de inicialización (Proceden del Padre): No sincronizamos
        if (!firstWatchInit.value) {
          emit('update:contact-ids', contactIdsLocal.value)
        }
        firstWatchInit.value = false
      },
    )

    // Internal contact list
    const contactFilterOptions = ref([])
    const fetchContacts = () => {
      useContact.fetchContacts()
        .then(response => {
          const { contacts } = response.data
          if (contacts) {
            contactFilterOptions.value = contacts
          } else {
            contactFilterOptions.value = []
          }
        })
    }
    fetchContacts()

    const onSelectChange = () => {
      // emit('update:contact-ids', contactIdsLocal.value)
    }

    // UI
    const onRemoveContact = item => {
      const index = contactIdsLocal.value.indexOf(item.id)
      if (index >= 0) contactIdsLocal.value.splice(index, 1)

      onSelectChange()
    }

    const errorMessagesLocal = ref([])

    // watch(() => props.errorMessages, () => {
    //   debugger
    //   errorMessagesLocal.value = props.errorMessages
    // })

    // TODO - No esta funcionando la validacion
    // Form validation
    const form = ref(null)
    const validationForm = () => {
      // const isFormValid = form.value.validate()
      const isFormValid = (contactIdsLocal.value.length > 0)
      if (isFormValid) {
        errorMessagesLocal.value = []
      } else {
        errorMessagesLocal.value = [{ contacts: 'Seleccione algún contacto' }]
      }

      return isFormValid
    }
    const validationFormPromise = () => new Promise((resolve, reject) => {
      const isFormValid = validationForm()
      if (!isFormValid) return reject()

      emit('update:contact-ids', contactIdsLocal.value) // ok

      return resolve(true)
    })

    return {
      // Data
      contactIdsLocal,
      contactFilterOptions,
      errorMessagesLocal,

      // Events
      onRemoveContact,
      onSelectChange,

      // Form Validators
      form,
      validationForm,
      validationFormPromise,
      rules: {
        select: [v => !!v || 'Item is required'],
        select2: [v => v.length > 0 || 'Item is required in select 2'],
      },
    }
  },
}
</script>
