<template>
  <v-card
    no-body
    class="rounded-0"
  >
    <v-card-title>
      <span>Goal Overview</span>
      <v-spacer></v-spacer>

      <!-- Refresh -->
      <v-btn
        icon
        small
        @click="$emit('on-calculate')"
      >
        <v-icon size="20">
          {{ icons.mdiRefresh }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <!-- apex chart -->
    <vue-apex-charts
      type="radialBar"
      height="245"
      class="my-2"
      :options="goalOverviewRadialBar"
      :series="series"
    />
    <v-row class="text-center mx-0">
      <v-col
        cols="4"
        class="border-top border-right d-flex align-items-between flex-column py-1"
      >
        <v-card-text class="text-muted mv-0">
          Goal
        </v-card-text>
        <h3 class="font-weight-bolder mv-0">
          {{ targetResultLocal.goal }}
        </h3>
      </v-col>

      <v-col
        cols="4"
        class="border-top border-right d-flex align-items-between flex-column py-1"
      >
        <v-card-text class="text-muted mv-0">
          Partial Affinity
        </v-card-text>
        <h3 class="font-weight-bolder mv-0">
          {{ targetResultLocal.partialAffinity }}
        </h3>
      </v-col>

      <v-col
        cols="4"
        class="border-top d-flex align-items-between flex-column py-1"
      >
        <v-card-text class="text-muted mv-0">
          Full Affinity
        </v-card-text>
        <h3 class="font-weight-bolder mv-0">
          {{ targetResultLocal.fullAffinity }}
        </h3>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mdiRefresh } from '@mdi/js'
import { ref, computed, watch } from '@vue/composition-api'
import VueApexCharts from 'vue-apexcharts'

// import { $themeColors } from '@themeConfig'

const $strokeColor = '#ebe9f1'
const $textHeadingColor = '#5e5873'
const $goalStrokeColor2 = '#51e5a8'
export default {
  components: {
    VueApexCharts,
  },
  props: {
    targetResult: {
      type: Object,
      default: () => ({
        goal: 100,
        partialAffinity: 80,
        fullAffinity: 40,
      }),
    },
  },
  setup(props) {
    const targetResultLocal = ref(props.targetResult)
    watch(() => props.targetResult, () => {
      targetResultLocal.value = props.targetResult
    })
    const series = computed(() => {
      const result = targetResultLocal.value.goal !== 0 ? ((targetResultLocal.value.fullAffinity / targetResultLocal.value.goal) * 100) : 0

      return [result > 100 ? 100 : result]
    })
    const goalOverviewRadialBar = {
      chart: {
        height: 245,
        type: 'radialBar',
        sparkline: {
          enabled: true,
        },
        dropShadow: {
          enabled: true,
          blur: 3,
          left: 1,
          top: 1,
          opacity: 0.1,
        },
      },
      colors: [$goalStrokeColor2],
      plotOptions: {
        radialBar: {
          offsetY: -10,
          startAngle: -150,
          endAngle: 150,
          hollow: {
            size: '77%',
          },
          track: {
            background: $strokeColor,
            strokeWidth: '50%',
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              color: $textHeadingColor,
              fontSize: '2.86rem',
              fontWeight: '600',
            },
          },
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: ['primary'],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: 'round',
      },
      grid: {
        padding: {
          bottom: 30,
        },
      },
    }

    return {
      targetResultLocal,
      goalOverviewRadialBar,
      series,

      icons: {
        mdiRefresh,
      },
    }
  },
}
</script>
